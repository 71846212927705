import { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ListProps,
  DateField,
} from "react-admin";

const searchFilter = [
  <TextInput source="send" label="Search by Sender" alwaysOn />,
  <TextInput source="received" label="Search by Receiver" alwaysOn />,
];

const SeniorList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick="show" isRowSelectable={(record) => false}>
      <TextField source="id" />
      <TextField source="send" />
      <TextField source="received" />
      <TextField source="status" />
      <TextField source="message" />
      <TextField source="feedback" />
      <DateField
        showTime
        locales="id-ID"
        source="created_at"
        label="Created At"
      />
      <DateField
        showTime
        locales="id-ID"
        source="updated_at"
        label="Updated At"
      />
    </Datagrid>
  </List>
);

export default SeniorList;
