import { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TextInput,
  ListProps,
} from "react-admin";

const searchFilter = [
  <TextInput source="nama_lengkap" label="Search by nama lengkap" alwaysOn />,
];

const UserList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="nama_lengkap" />
      <TextField source="nama_panggilan" />
      <TextField source="tempat_lahir" />
      <TextField source="tanggal_lahir" />
      <TextField source="jenis_kelamin" />
      <TextField source="jurusan" />
      <TextField source="angkatan" />
      <TextField source="asal_sekolah" />
      <TextField source="id_line" />
      <TextField source="instagram" />
      <TextField source="bio" />
      <TextField source="foto_profil" />
      <TextField source="foto_profil_type" />
      <ReferenceField source="kelompok_id" reference="kelompok_besar">
        <TextField source="nama" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default UserList;
