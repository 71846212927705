import MabaReviewList from "components/mabaReviews/MabaReviewList";
import MabaReviewShow from "components/mabaReviews/MabaReviewShow";
import MabaReviewEdit from "components/mabaReviews/MabaReviewEdit";

const MabaReview = {
  list: MabaReviewList,
  show: MabaReviewShow,
  edit: MabaReviewEdit,
};

export default MabaReview;
