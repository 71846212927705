import { FC } from "react";
import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  Show,
  UrlField,
  DateField,
} from "react-admin";

const SubmissionShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Submission ID" />
        <UrlField source="fileAWSKey" label="File from AWS" />
        <DateField
          showTime
          locales="id-ID"
          source="submitTime"
          label="Submission Time"
        />
        <UrlField source="submitLink" label="Submit link" />
        <TextField source="assignmentId" />
        <TextField source="userusername" label="Username" />
        <TextField source="feedback" label="Feedback" />
      </SimpleShowLayout>
    </Show>
  );
};

export default SubmissionShow;
