import { CreateParams, DataProvider, fetchUtils, UpdateParams } from "ra-core";
import simpleRestProvider from "ra-data-simple-rest";
import axios from "axios";

interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

const fetchJson = (url: string, options: Options = {}) => {
  options.headers = new Headers({
    Authorization: `JWT ${localStorage.getItem("token-admin")}`,
  });

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_ADMIN_BACKEND_URL as string,
  fetchJson
);

const badgeIdProcessor = (badgeids: { badgeids: string }[]): string[] => {
  // @ts-ignore
  const mappedBadge = badgeids.map((badge) => badge.badgeId);
  const res: string[] = [];
  mappedBadge.map((badge) => res.push(badge));

  return res;
};

const generatePutUrl = async (
  fileName: String,
  fileType: String,
  resource: string
) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_ADMIN_BACKEND_URL as string
    }/${resource}/generate-put-url`,
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token-admin")}`,
      },
      params: {
        file_name: fileName,
        file_type: fileType,
      },
    }
  );

  return response;
};

const awsPut = async (preSignedUrl: string, imageFile: any) => {
  const customAxios = axios.create();
  const uploadRes = await customAxios.put(preSignedUrl, imageFile.rawFile, {
    headers: {
      "content-type": imageFile.rawFile.type,
    },
  });
  return uploadRes;
};

const myDataProvider = {
  ...dataProvider,
  create: async (resource: string, params: CreateParams<any>) => {
    if (resource === "carousel") {
      const imageFile = params.data.image;
      const rawFile = imageFile.rawFile;

      const fileName: string = rawFile.name as string;
      const fileType: string = rawFile.type as string;

      const response = await generatePutUrl(fileName, fileType, resource);

      const file_aws_key = response.data.content[0].file_aws_key;
      const preSignedUrl = response.data.content[1].presigned_url;

      const awsResponse = await awsPut(preSignedUrl, imageFile);

      return dataProvider.create(resource, {
        ...params,
        data: {
          description: params.data.description,
          image: file_aws_key,
          image_type: fileType,
        },
      });
    }

    if (resource === "events") {
      const imageFile = params.data.image;

      if (!imageFile) {
        return dataProvider.create(resource, {
          ...params,
          data: {
            namaEvent: params.data.namaEvent,
            namaOrganizer: params.data.namaOrganizer,
            location: params.data.location,
            description: params.data.description,
            isInternal: params.data.isInternal ? true : false,
            dateTime: params.data.dateTime,
            image: null,
            imageType: null,
          },
        });
      }

      const rawFile = imageFile.rawFile;

      const fileName: string = rawFile.name as string;
      const fileType: string = rawFile.type as string;

      const response = await generatePutUrl(fileName, fileType, resource);

      const file_aws_key = response.data.content[0].file_aws_key;
      const preSignedUrl = response.data.content[1].presigned_url;

      const awsResponse = await awsPut(preSignedUrl, imageFile);

      return dataProvider.create(resource, {
        ...params,
        data: {
          namaEvent: params.data.namaEvent,
          namaOrganizer: params.data.namaOrganizer,
          location: params.data.location,
          description: params.data.description,
          isInternal: params.data.isInternal ? true : false,
          dateTime: params.data.dateTime,
          image: file_aws_key,
          imageType: fileType,
        },
      });
    }
    return dataProvider.create(resource, params);
  },
  update: async (resource: string, params: UpdateParams<any>) => {
    if (resource === "carousel") {
      const imageFile = params.data.image;
      const rawFile = imageFile.rawFile;

      const fileName: string = rawFile.name as string;
      const fileType: string = rawFile.type as string;

      const response = await generatePutUrl(fileName, fileType, resource);

      const file_aws_key = response.data.content[0].file_aws_key;
      const preSignedUrl = response.data.content[1].presigned_url;

      const awsResponse = await awsPut(preSignedUrl, imageFile);

      return dataProvider.update(resource, {
        ...params,
        data: {
          id: params.data.id,
          description: params.data.description,
          image: file_aws_key,
          image_type: fileType,
        },
      });
    } else if (resource === "events") {
      const imageFile = params.data.image;
      let rawFile;

      if (imageFile) {
        rawFile = imageFile.rawFile;
      }

      if (!rawFile) {
        return dataProvider.update(resource, {
          ...params,
          data: {
            id: params.data.id,
            namaEvent: params.data.namaEvent,
            namaOrganizer: params.data.namaOrganizer,
            location: params.data.location,
            description: params.data.description,
            isInternal: params.data.isInternal ? true : false,
            dateTime: params.data.dateTime,
          },
        });
      }

      const fileName: string = rawFile.name as string;
      const fileType: string = rawFile.type as string;

      const response = await generatePutUrl(fileName, fileType, resource);

      const file_aws_key = response.data.content[0].file_aws_key;
      const preSignedUrl = response.data.content[1].presigned_url;

      const awsResponse = await awsPut(preSignedUrl, imageFile);

      return dataProvider.update(resource, {
        ...params,
        data: {
          id: params.data.id,
          namaEvent: params.data.namaEvent,
          namaOrganizer: params.data.namaOrganizer,
          location: params.data.location,
          description: params.data.description,
          isInternal: params.data.isInternal ? true : false,
          dateTime: params.data.dateTime,
          image: file_aws_key,
          imageType: fileType,
        },
      });
    } else if (resource !== "user") {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }
    return dataProvider.update(resource, {
      ...params,
      data: {
        ...params.data,
        badgeids: badgeIdProcessor(params.data.badgeids),
      },
    });
  },
};

export default myDataProvider as DataProvider;
