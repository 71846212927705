import { FC } from "react";
import { List, Datagrid, TextField, ListProps } from "react-admin";

const BadgeList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="logo" />
    </Datagrid>
  </List>
);

export default BadgeList;
