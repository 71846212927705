import { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ListProps,
  DateField,
} from "react-admin";

const searchFilter = [
  <TextInput source='namaEvent' label='Search by nama event' alwaysOn />,
];

const EventList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick='show'>
      <TextField source='namaEvent' />
      <TextField source='namaOrganizer' />
      <TextField source='description' />
      <DateField
        source='dateTime'
        showTime
        options={{
          hour12: false,
          year: "numeric",
          month: "long",
          day: "numeric",
          minute: "numeric",
          hour: "numeric",
        }}
      />
      <TextField source='location' />
      <TextField source='isInternal' label='Internal Event' />
    </Datagrid>
  </List>
);

export default EventList;
