import LeaderboardKelompokList from "./LeaderboardKelompokList";
import LeaderboardKelompokCreate from "./LeaderboardKelompokCreate";
import LeaderboardKelompokEdit from "./LeaderboardKelompokEdit";
import LeaderboardKelompokShow from "./LeaderboardKelompokShow";

const LeaderboardKelompok = {
    list: LeaderboardKelompokList,
    create: LeaderboardKelompokCreate,
    edit: LeaderboardKelompokEdit,
    show: LeaderboardKelompokShow
}

export default LeaderboardKelompok;