import { FC } from "react";
import { ShowProps, SimpleShowLayout, TextField, Show } from "react-admin";

const LeaderboardShow: FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="score" />
            <TextField source="month" />
        </SimpleShowLayout>
    </Show>
)

export default LeaderboardShow;