import { FC } from "react";
import { List, Datagrid, TextField, ListProps, Filter, SelectInput } from "react-admin";

const LeaderboardFilter: FC = (props) => (
    <Filter {...props}>
        <SelectInput 
            label="Month" 
            source="month" 
            choices={[
                { id: 'january', name: 'January' },
                { id: 'february', name: 'February' },
                { id: 'march', name: 'March' },
                { id: 'april', name: 'April' },
                { id: 'may', name: 'May' },
                { id: 'june', name: 'June' },
                { id: 'july', name: 'July' },
                { id: 'august', name: 'August' },
                { id: 'september', name: 'September' },
                { id: 'october', name: 'October' },
                { id: 'november', name: 'November' },
                { id: 'december', name: 'December' }
              ]}
            alwaysOn
            emptyText="No Filter" 
        />
    </Filter>
);

const LeaderboardList: FC<ListProps> = (props) => (
    <List {...props} filters={<LeaderboardFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="score" />
            <TextField source="month" />
        </Datagrid>
    </List>
);

export default LeaderboardList;
