import { FC } from "react";
import {
  Show,
  TextField,
  TextInput,
  ShowProps,
  SimpleShowLayout,
} from "react-admin";

const QuestionShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="question" />
    </SimpleShowLayout>
  </Show>
);

export default QuestionShow;
