import { FC } from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  CreateProps,
} from "react-admin";

const UserCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="username" />
      <TextInput source="nama_lengkap" />
      <TextInput source="nama_panggilan" />
      <TextInput source="tempat_lahir" />
      <TextInput source="tanggal_lahir" />
      <TextInput source="jenis_kelamin" />
      <TextInput source="jurusan" />
      <TextInput source="angkatan" />
      <TextInput source="asal_sekolah" />
      <TextInput source="id_line" />
      <TextInput source="instagram" />
      <TextInput source="bio" />
      <TextInput source="foto_profil" />
      <TextInput source="foto_profil_type" />
      <TextInput source="role" />
      <TextInput source="email" />
      <TextInput source="refresh_token" />
      <ReferenceInput source="kelompok_id" reference="kelompok_besar">
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default UserCreate;
