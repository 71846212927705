import { FC, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  EditProps,
  ChipField,
  ReferenceManyField,
  SingleFieldList,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import axios from "axios";

const UserEdit: FC<EditProps> = (props) => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    const getBadges = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_ADMIN_BACKEND_URL}/badge?filter={}&range=[0,50]&sort=["id", "ASC"]`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token-admin")}`,
          },
        }
      );
      setBadges(
        res.data.map((badge: { id: string; logo: string }) => ({
          id: badge.id,
          logo: badge.logo,
        }))
      );
    };

    getBadges();
  }, []);

  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput disabled source="id" />
        <TextInput source="username" />
        <TextInput source="nama_lengkap" />
        <TextInput source="nama_panggilan" />
        <TextInput source="tempat_lahir" />
        <TextInput source="tanggal_lahir" />
        <TextInput source="jenis_kelamin" />
        <TextInput source="jurusan" />
        <TextInput source="angkatan" />
        <TextInput source="asal_sekolah" />
        <TextInput source="id_line" />
        <TextInput source="instagram" />
        <TextInput source="bio" />
        <TextInput source="foto_profil" />
        <TextInput source="foto_profil_type" />
        <TextInput source="role" />
        <TextInput source="email" />
        <TextInput source="refresh_token" />
        <ArrayInput source="badgeids" label="Badge">
          <SimpleFormIterator>
            <SelectInput source="badgeId" choices={badges} optionText="logo" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
