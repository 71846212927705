import { FC } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  DateTimeInput,
} from "react-admin";

const AssignmentCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="namaAssignment" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="dueDate" />
      <TextInput source="description" />
      <TextInput source="link" />
    </SimpleForm>
  </Create>
);

export default AssignmentCreate;
