import { FC } from "react";
import { ShowProps, SimpleShowLayout, TextField, Show } from "react-admin";

const AssignmentShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="namaAssignment" />
        <TextField source="dueDate" />
        <TextField source="description" />
        <TextField source="link" />
        <TextField source="startDate" />
      </SimpleShowLayout>
    </Show>
  );
};

export default AssignmentShow;
