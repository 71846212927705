import QuestionList from "components/questions/QuestionList";
import QuestionCreate from "components/questions/QuestionCreate";
import QuestionShow from "components/questions/QuestionShow";
import QuestionEdit from "components/questions/QuestionEdit";

const Questions = {
  list: QuestionList,
  create: QuestionCreate,
  show: QuestionShow,
  edit: QuestionEdit,
};

export default Questions;
