import { FC } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  required,
  ImageInput,
  ImageField,
} from "react-admin";

const CarouselCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput validate={[required()]} source='description' resettable />
      <ImageInput
        source='image'
        label='Carousel Image'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default CarouselCreate;