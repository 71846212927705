import SubmissionList from "components/submissions/SubmissionList";
import SubmissionShow from "components/submissions/SubmissionShow";
import SubmissionEdit from "components/submissions/SubmissionEdit";

const Submissions = {
  list: SubmissionList,
  show: SubmissionShow,
  edit: SubmissionEdit,
};

export default Submissions;
