import { FC, Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ListProps,
  BulkDeleteButton,
} from "react-admin";

const searchFilter = [
  <TextInput source="description" label="Search by description" alwaysOn />,
];

const bulkDeletButtons = () => {
  <Fragment>
      <BulkDeleteButton />
  </Fragment>
}

const CarouselList: FC<ListProps> = (props) => (
  <List filters={searchFilter} bulkActionButtons={<BulkDeleteButton />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="description" />
      <TextField source="image" />
    </Datagrid>
  </List>
);

export default CarouselList;
