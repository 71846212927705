import { FC } from "react";
import { List, Datagrid, TextField, ListProps, TextInput } from "react-admin";

const UserInterestList: FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid isRowSelectable={(record) => false}>
      <TextField source="user_id" label="User ID" />
      <TextField source="interest_id" label="Interest ID" />
    </Datagrid>
  </List>
);

export default UserInterestList;
