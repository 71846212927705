import { FC } from "react";
import { List, Datagrid, TextField, TextInput, ListProps } from "react-admin";

const searchFilter = [
  <TextInput source="namaAssignment" label="Search by nama assignment" alwaysOn />,
];

const AssignmentList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="namaAssignment" />
      <TextField source="dueDate" />
      <TextField source="description" />
      <TextField source="link" />
      <TextField source="startDate" />
    </Datagrid>
  </List>
);

export default AssignmentList;
