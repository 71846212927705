import { FC } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  EditProps,
  Edit,
} from "react-admin";

const CarouselEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput validate={[required()]} source='description' resettable />
      <ImageInput
        source='image'
        label='Carousel Image'
        validate={[required()]}
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default CarouselEdit;