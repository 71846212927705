import { FC } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  DateTimeInput,
  BooleanInput,
  required,
  ImageInput,
  ImageField,
  SelectInput,
} from "react-admin";

const EventCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput validate={[required()]} source='namaEvent' />
      <TextInput validate={[required()]} source='namaOrganizer' />
      <TextInput validate={[required()]} source='description' resettable />
      <DateTimeInput source='dateTime' validate={[required()]} />
      <TextInput
        validate={[required()]}
        source='location'
        label='Location/Meeting link'
      />
      <BooleanInput
        label='Internal event (toggle on if this is an internal event)'
        source='isInternal'
        fullWidth
      />
      <ImageInput
        source='image'
        label='Organizer Logo (not needed if the event is an internal event or a PMB event)'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default EventCreate;
