import { FC } from "react";
import { Create, SimpleForm, TextInput, CreateProps } from "react-admin";

const QuestionCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="question" />
    </SimpleForm>
  </Create>
);

export default QuestionCreate;
