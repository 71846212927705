import { FC } from "react";
import { EditProps, SimpleForm, TextInput, Edit } from "react-admin";

const SubmissionEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput source="id" label="Submission ID" disabled fullWidth />
        <TextInput
          source="fileAWSKey"
          label="File from AWS"
          disabled
          fullWidth
        />
        <TextInput
          source="submitTime"
          label="Submission Time"
          disabled
          fullWidth
        />
        <TextInput source="submitLink" label="Submit link" disabled fullWidth />
        <TextInput source="assignmentId" disabled style={{ width: "100%" }} />
        <TextInput source="userusername" label="Username" disabled fullWidth />
        <TextInput source="feedback" label="Feedback" multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export default SubmissionEdit;
