import LeaderboardList from "./LeaderboardList";
import LeaderboardCreate from "./LeaderboardCreate";
import LeaderboardEdit from "./LeaderboardEdit";
import LeaderboardShow from "./LeaderboardShow";

const Leaderboard = {
    list: LeaderboardList,
    create: LeaderboardCreate,
    edit: LeaderboardEdit,
    show: LeaderboardShow
}

export default Leaderboard;