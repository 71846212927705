const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const request = new Request(
    `${process.env.REACT_APP_ADMIN_BACKEND_URL}/login`,
    {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );

  const response = await fetch(request);
  console.log(response)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }

  const { username: usernameResponse, role, token } = await response.json();
  localStorage.setItem("username-admin", usernameResponse);
  localStorage.setItem("permissions-admin", role);
  localStorage.setItem("token-admin", token);
};

const logout = () => {
  localStorage.removeItem("username-admin");
  localStorage.removeItem("permissions-admin");
  localStorage.removeItem("token-admin");
  return Promise.resolve();
};

const checkError = ({ status }: { status: number }) => {
  if (status === 401 || status === 403) {
    localStorage.removeItem("username-admin");
    localStorage.removeItem("permissions-admin");
    localStorage.removeItem("token-admin");
    return Promise.reject();
  }
  return Promise.resolve();
};

const checkAuth = () => {
  return window.localStorage.getItem("token-admin")
    ? Promise.resolve()
    : Promise.reject();
};

const getPermissions = async () => {
  const token = localStorage.getItem("token-admin");
  const role = localStorage.getItem("permissions-admin");
  const username = localStorage.getItem("username-admin");

  const request = new Request(
    `${process.env.REACT_APP_ADMIN_BACKEND_URL}/verify`,
    {
      method: "POST",
      body: JSON.stringify({ username, role }),
      headers: new Headers({
        Authorization: "JWT " + token,
        "Content-Type": "application/json",
      }),
    }
  );

  const response = await fetch(request);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject();
  }

  return Promise.resolve(role);
};

const AuthProvider = {
  // called when the user attempts to log in
  login: login,
  // called when the user clicks on the logout button
  logout: logout,
  // called when the API returns an error
  checkError: checkError,
  // called when the user navigates to a new location, to check for authentication
  checkAuth: checkAuth,
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: getPermissions,
};

export default AuthProvider;
