import { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ListProps,
  ReferenceInput,
  SelectInput,
  ReferenceField,
} from "react-admin";

const searchFilter = [
  <TextInput source="userusername" label="Search by Username" alwaysOn />,
  <ReferenceInput
    source="assignmentId"
    label="Nama assignment"
    reference="assignment"
    allowEmpty={false}
  >
    <SelectInput optionText="namaAssignment" />
  </ReferenceInput>,
];

const SubmissionList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="Submission ID" />
      <TextField source="submitTime" />
      <ReferenceField source="assignmentId" label="Nama assignment" reference="assignment">
        <TextField source="namaAssignment" />
      </ReferenceField>
      <TextField source="userusername" label="Username" />
    </Datagrid>
  </List>
);

export default SubmissionList;
