import CarouselList from "./carouselList";
import CarouselCreate from "./carouselCreate";
import CarouselEdit from "./carouselEdit";

const Carousel = {
  list: CarouselList,
  create: CarouselCreate,
  edit: CarouselEdit
};

export default Carousel;
