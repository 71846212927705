import { FC } from "react";
import {
  ShowProps,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  SelectField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  ArrayField,
  Datagrid,
} from "react-admin";

const UserShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="nama_lengkap" />
      <TextField source="nama_panggilan" />
      <TextField source="tempat_lahir" />
      <TextField source="tanggal_lahir" />
      <TextField source="jenis_kelamin" />
      <TextField source="jurusan" />
      <TextField source="angkatan" />
      <TextField source="asal_sekolah" />
      <TextField source="id_line" />
      <TextField source="instagram" />
      <TextField source="bio" />
      <TextField source="foto_profil" />
      <TextField source="foto_profil_type" />
      <ArrayField source="Owned_Badge" label="Badges">
        <Datagrid>
          <TextField source="badge.logo" label="Badge" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
