import AssignmentList from "./AssignmentList";
import AssignmentEdit from "./AssignmentEdit";
import AssignmentShow from "./AssignmentShow";
import AssignmentCreate from "./AssignmentCreate";

const Assignments = {
  list: AssignmentList,
  edit: AssignmentEdit,
  show: AssignmentShow,
  create: AssignmentCreate,
};

export default Assignments;
