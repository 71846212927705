import EventList from "components/events/EventList";
import EventEdit from "components/events/EventEdit";
import EventShow from "components/events/EventShow";
import EventCreate from "components/events/EventCreate";

const Events = {
  list: EventList,
  edit: EventEdit,
  show: EventShow,
  create: EventCreate,
};

export default Events;
