import { FC } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  DateTimeInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,
} from "react-admin";

const EventEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm redirect='list'>
      <TextInput disabled source='id' />
      <TextInput validate={[required()]} source='namaEvent' />
      <TextInput validate={[required()]} source='namaOrganizer' />
      <TextInput validate={[required()]} source='description' resettable />
      <DateTimeInput source='dateTime' validate={[required()]} />
      <TextInput
        validate={[required()]}
        source='location'
        label='Location/Meeting link'
      />
      <BooleanInput
        label='Internal event (toggle on if this is an internal event)'
        source='isInternal'
        fullWidth
      />

      <ImageInput
        source='image'
        label='Organizer Logo (not needed if the event is an internal event or a PMB event)'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default EventEdit;
