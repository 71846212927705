import { FC } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  DateTimeInput,
} from "react-admin";

const AssignmentEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput disabled source="id" />
      <TextInput source="namaAssignment" />
      <DateTimeInput source="startDate" label="Start date (in UTC)" />
      <DateTimeInput source="dueDate" label="Due date (in UTC)" />
      <TextInput source="description" />
      <TextInput source="link" />
    </SimpleForm>
  </Edit>
);

export default AssignmentEdit;
