import { FC } from "react";
import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  Show,
  ImageField,
  DateField,
} from "react-admin";

const EventShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <TextField source='namaEvent' />
        <TextField source='namaOrganizer' />
        <TextField source='description' />
        <DateField
          source='dateTime'
          showTime
          options={{
            hour12: false,
            year: "numeric",
            month: "long",
            day: "numeric",
            minute: "numeric",
            hour: "numeric",
          }}
        />
        <TextField source='location' />
        <TextField source='isInternal' label='Internal Event' />
        <ImageField source='image' label='Icon' />
      </SimpleShowLayout>
    </Show>
  );
};

export default EventShow;
