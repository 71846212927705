import { FC } from "react";
import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  Show,
  UrlField,
  ArrayField,
  Datagrid,
  BooleanField,
  DateField,
} from "react-admin";

const MabaReviewShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="maba_sender" />
        <TextField source="maba_receiver" />
        <TextField source="status" />
        <DateField
          showTime
          locales="id-ID"
          source="submitted_at"
          label="Submission Time"
        />
        <UrlField source="photo" />
        <TextField source="feedback" />
        <ArrayField source="question_for_maba" label="Daftar Pertanyaan">
          <Datagrid>
            <TextField source="question" />
            <TextField source="answer" />
            <BooleanField source="is_correct" label="Is Correct?" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default MabaReviewShow;
