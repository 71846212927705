import { FC } from "react";
import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  Show,
  DateField,
} from "react-admin";

const SeniorReviewShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="send" />
        <TextField source="received" />
        <TextField source="status" />
        <TextField source="feedback" />
        <TextField source="message" />
        <DateField
          showTime
          locales="id-ID"
          source="created_at"
          label="Created At"
        />
        <DateField
          showTime
          locales="id-ID"
          source="updated_at"
          label="Updated At"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default SeniorReviewShow;
