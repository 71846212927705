import { Card, CardContent, CardHeader } from "@material-ui/core";

const Dashboard = () => (
  <Card>
    <CardHeader title="PMB Admin Page" />
    <CardContent>
      Welcome! Please use <b>Chrome/Edge</b> for better experience.
    </CardContent>
  </Card>
);

export default Dashboard;
