import { FC } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  UrlField,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  BooleanInput,
  DateField,
} from "react-admin";

const MabaReviewEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput disabled source="id" fullWidth />
      <TextInput disabled source="maba_sender" fullWidth />
      <TextInput disabled source="maba_receiver" fullWidth />
      <SelectInput
        fullWidth
        source="status"
        choices={[
          { id: "Matched", name: "Matched" },
          { id: "In Verification", name: "In Verification" },
          { id: "Accepted", name: "Accepted" },
          { id: "Rejected", name: "Rejected" },
        ]}
      />
      <DateField
        showTime
        locales="id-ID"
        source="submitted_at"
        label="Submission Time"
      />
      <UrlField source="photo" />
      <TextInput source="feedback" label="Feedback" multiline fullWidth />
      <ArrayInput source="question_for_maba">
        <SimpleFormIterator disableRemove disableAdd>
          <TextInput disabled fullWidth source="question" label="Question" />
          <TextInput disabled fullWidth source="answer" label="Answer" />
          <BooleanInput source="is_correct" label="Is Correct?" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default MabaReviewEdit;
