import { FC } from "react";
import { Edit, SimpleForm, TextInput, EditProps } from "react-admin";

const QuestionEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput disabled source="id" />
      <TextInput source="question" />
    </SimpleForm>
  </Edit>
);

export default QuestionEdit;
