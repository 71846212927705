import { FC } from "react";
import { Create, SimpleForm, SelectInput, ArrayInput, SimpleFormIterator, TextInput, CreateProps } from "react-admin";

const months = [
  { id: 'january', name: 'January' },
  { id: 'february', name: 'February' },
  { id: 'march', name: 'March' },
  { id: 'april', name: 'April' },
  { id: 'may', name: 'May' },
  { id: 'june', name: 'June' },
  { id: 'july', name: 'July' },
  { id: 'august', name: 'August' },
  { id: 'september', name: 'September' },
  { id: 'october', name: 'October' },
  { id: 'november', name: 'November' },
  { id: 'december', name: 'December' },
];

const LeaderboardKelompokCreate: FC<CreateProps> = (props) => ( 
  <Create {...props}>
    <SimpleForm redirect="list">
      <SelectInput label="Month" source="month" choices={months} />
      <ArrayInput source="kelompoks">
        <SimpleFormIterator>
          <TextInput label="Nama Kelompok" source="nama_kelompok" />
          <TextInput label="Score" source="score" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default LeaderboardKelompokCreate;
