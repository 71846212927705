import { FC } from "react";
import { Edit, SimpleForm, TextInput, EditProps, SelectInput } from "react-admin";
const months = [
    { id: 'january', name: 'January' },
    { id: 'february', name: 'February' },
    { id: 'march', name: 'March' },
    { id: 'april', name: 'April' },
    { id: 'may', name: 'May' },
    { id: 'june', name: 'June' },
    { id: 'july', name: 'July' },
    { id: 'august', name: 'August' },
    { id: 'september', name: 'September' },
    { id: 'october', name: 'October' },
    { id: 'november', name: 'November' },
    { id: 'december', name: 'December' },
  ];
  
const LeaderboardKelompokEdit: FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput disabled source="id" />
            <TextInput source="nama_kelompok" />
            <TextInput source="score" />    
            <SelectInput label="Month" source="month" choices={months} />
        </SimpleForm>
    </Edit>
)

export default LeaderboardKelompokEdit;