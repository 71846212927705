import { FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ListProps,
  DateField,
  SelectInput,
  Pagination,
} from "react-admin";

const searchFilter = [
  <TextInput source="maba_sender" label="Search by Maba Sender" alwaysOn />,
  <TextInput source="maba_receiver" label="Search by Maba Receiver" alwaysOn />,
  <SelectInput
    fullWidth
    source="status"
    choices={[
      { id: "Matched", name: "Matched" },
      { id: "In Verification", name: "In Verification" },
      { id: "Accepted", name: "Accepted" },
      { id: "Rejected", name: "Rejected" },
    ]}
    alwaysOn
  />,
];

const MabaReviewList: FC<ListProps> = (props) => (
  <List filters={searchFilter} 
    perPage={100} 
    pagination={<Pagination rowsPerPageOptions={[100, 200, 500, 1000]} />} {...props}
  >
    <Datagrid rowClick="show" isRowSelectable={(record) => false}>
      <TextField source="id" />
      <TextField source="maba_sender" />
      <TextField source="maba_receiver" />
      <TextField source="status" />
      <DateField
        showTime
        locales="id-ID"
        source="submitted_at"
        label="Submission Time"
      />
    </Datagrid>
  </List>
);

export default MabaReviewList;
