import { FC } from "react";
import { List, Datagrid, TextField, ListProps } from "react-admin";

const KelompokMappingList: FC<ListProps> = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="nama" />
      </Datagrid>
    </List>
  );
};

export default KelompokMappingList;
