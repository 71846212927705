import "./App.css";
import authProvider from "api/authProvider";
import dataProvider from "api/dataProvider";
import {
  Admin,
  Resource,
  ListGuesser,
  // EditGuesser,
  // ShowGuesser,
} from "react-admin";
// import users from "components/users";
import assignments from "components/assignments";
import questions from "components/questions";
import userInterests from "components/userInterests";
import Dashboard from "components/dashboard/Dashboard";
import kelompokMapping from "components/kelompokMapping";
import submissions from "components/submissions";
import UserList from "components/users/UserList";
import UserCreate from "components/users/UserCreate";
import UserEdit from "components/users/UserEdit";
import UserShow from "components/users/UserShow";
import mabaReviews from "components/mabaReviews";
import SeniorReview from "components/seniors";
import badges from "components/badges";
import ownedBadges from "components/ownedBadges";
import carousels from "components/carousel";
import events from "components/events";
import Leaderboard from "components/leaderboard";
import LeaderboardKelompok from "components/leaderboardKelompok";

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    {(permissions) => [
      <Resource name='maba_review' {...mabaReviews} />,
      permissions !== "mentor" && (
        <Resource name='submission' {...submissions} />
      ),
      permissions !== "mentor" && <Resource name='senior' {...SeniorReview} />,
      permissions !== "mentor" && <Resource name='assignment' {...assignments} />,
      permissions !== "mentor" && <Resource name='leaderboard' {...Leaderboard} />,
      permissions !== "mentor" && (<Resource name='leaderboard_kelompok' {...LeaderboardKelompok} />
      ),
      permissions !== "mentor" && <Resource name='events' {...events} />,
      permissions !== "mentor" && <Resource name='carousel' {...carousels} />,
      permissions !== "mentor" && <Resource name='question' {...questions} />,
      permissions !== "mentor" && (
        <Resource name='user_interest' {...userInterests} />
      ),
      permissions !== "mentor" && (
        <Resource name='kelompok_besar' {...kelompokMapping} />
      ),
      permissions !== "mentor" && <Resource name='badge' {...badges} />,
      permissions !== "mentor" && (
        <Resource name='owned_badge' {...ownedBadges} />
      ),
      permissions !== "mentor" && (
        <Resource
          name='user'
          show={UserShow}
          edit={UserEdit}
          create={permissions === "dev" ? UserCreate : undefined}
          list={UserList}
        />
      ),
    ]}
  </Admin>
);

export default App;
