import { FC } from "react";
import { List, Datagrid, TextField, TextInput, ListProps } from "react-admin";

const searchFilter = [
  <TextInput source="question" label="Search by question" alwaysOn />,
];

const QuestionList: FC<ListProps> = (props) => (
  <List filters={searchFilter} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="question" />
    </Datagrid>
  </List>
);

export default QuestionList;
